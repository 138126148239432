.helpMessageDev {
    position: inherit;
    left: 16px;
    bottom: 16px;
    text-align: left;
    background: rgba(0, 0, 0, 0.7);
    padding: 8px 24px;
    border-radius: 32px;
    opacity: 0.95;
    backdrop-filter: blur(8px);
    color: white;
}

.helpMessageDev span {
    font-size: 14px;
    color: #09f;
    opacity: 0.8;
}

.helpMessageDev h4,
.helpMessageDev h5 {
    margin: 0;
    display: flex;
}
